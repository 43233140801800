const nodes = document.querySelectorAll('.gen-dropdown');

nodes.forEach(node => node.addEventListener('click', function (e) {
    // Close all dropdowns
    nodes.forEach(otherNode => {
        if (otherNode !== node && otherNode.classList.contains("gen-dropdown--opened")) {
            otherNode.classList.remove("gen-dropdown--opened");
        }
    });

    // Toggle the clicked dropdown
    node.classList.toggle("gen-dropdown--opened");
    
    e.stopPropagation();
}));

document.body.addEventListener('click', function () {
    // Close all dropdowns
    nodes.forEach(node => {
        if (node.classList.contains("gen-dropdown--opened")) {
            node.classList.remove("gen-dropdown--opened");
        }
    });
});

const menuItems = document.querySelectorAll('.gen-dropdown__menu-item');

menuItems.forEach(function(item) {
    item.addEventListener('click', function() {
        const dropdown = item.closest('.gen-dropdown');
        if (!dropdown.classList.contains('disable')) {
            const buttonText = item.innerHTML;
            const buttonTextContainer = dropdown.querySelector('.gen-dropdown__button--text');
            buttonTextContainer.innerHTML = buttonText;
        }
    });
});
