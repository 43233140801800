export class Modal {
    constructor(selector) {
        this.modal = document.querySelector(`#${selector}`);
        this.toggleBtn = document.querySelectorAll(`[data-target="${selector}"]`);
        this.closeBtn = document.querySelectorAll(`[data-close="${selector}"]`);
        this.overlay = null;
        this.addEventListeners();
    }
    addEventListeners() {
        this.toggleBtn.forEach(btn => btn.addEventListener('click', () => this.toggleModal()));
        this.closeBtn.forEach(btn => btn.addEventListener('click', () => this.toggleModal()));
        // this.toggleBtn.addEventListener('click', () => this.toggleModal());
        // this.closeBtn.addEventListener('click', () => this.toggleModal());
    }
    createOverlay() {
        this.overlay = document.createElement('div');
        this.overlay.style.position = 'fixed';
        this.overlay.style.top = '0';
        this.overlay.style.left = '0';
        this.overlay.style.width = '100%';
        this.overlay.style.height = '100%';
        this.overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        this.overlay.style.zIndex = '999';
        this.overlay.style.display = 'block';
        this.overlay.addEventListener('click', () => this.toggleModal());
        document.body.appendChild(this.overlay);
    }
    toggleModal() {
        if (!this.overlay) {
            this.createOverlay();
        } else if (this.overlay) {
            this.overlay.removeEventListener('click', () => this.toggleModal());
            this.overlay.parentNode.removeChild(this.overlay);
            this.overlay = null;
        }
        this.modal.classList.toggle('open');
        document.body.classList.toggle('offcanvas-open');
    }
}